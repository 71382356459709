import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { Row, Col, Alert, Container } from "reactstrap"
import axios from "axios"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.png"
import swal from 'sweetalert'
import "../../helpers/helper";

const Desclimer = (props) => {
  useEffect(() => {
    const form = document.getElementById("form_login");
    const agree = document.getElementById("agree");

    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {

      e.preventDefault();

      const iAgree = agree
      if (iAgree.value) {
        agreed();
      }
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const agreed = async () => {
      const agreeRes = await axios.get(global.config.URL.api.lms + 'agree-whatsapp');

      if (agreeRes.status == 200) {
        swal({
          title: "Thanks For Accepting",
          text: "Now you can join whastapp group",
          icon: "success",
          button: "OK!",
        }).then(() => {
              window.location.href = '/whatsapp';
          });
      }
    }
  }, []);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Wahstapp Terms</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">National Initiative for AI & Security</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>
          <Row className="justify-content-center">
            
            <Col md={12} lg={12} xl={12}>
              <div className="card overflow-hidden">
                
                <div className="card-body pt-5">
                  <div className="p-2">
                    <h2>WAHSTAPP TERMS</h2>
                    <p>
                      <ul>
                       
                        <li>This WhatsApp group is only for the Course's support and it is not allowed to text any irrelevant messages in it.</li>
                        <li>You are allowed to do discussion via texts with the management and the trainers of your respective courses only. </li>
                        <li>It is not allowed to do any personal chat.</li>
                        <li>Personal chat with female members is strictly prohibited and if we get and complain then management has the right to take legal action and your course will be canceled.</li>
                        <li>If you face any issue or delay from your query you can directly approach the admin of the group. It is not allowed in the group to text again and again to disturb all members.</li>
                        <li>Admin is only responsible to reply in official timings and the trainer will respond you within 2 to 4 working days because of his/her busy schedule.</li>
                        <li>It is not allowed to text directly on trainer's contact number. In case this happens, management has the right to take strict action and may remove you from the course and the group.</li>
                        <li>You can only do texting in your relevant group during your course duration.</li>
                        <li>You can ask any query related to LMS, Quiz, assignment and Grand text regarding your certificate but you are not allowed to answer in the group.</li>
                        <li>Management has the right to add/modify any policy, terms and condition at any stage of your course and you are bound to agree with them.</li>
                        <li>Usage of unethical language is not allowed and if anyone uses then management has the right to take legal action without any prior warning.</li>
                        <li>If you are not following any instructions or break any rule, management has the right to charge you with fine.</li>
                        <li>Only those students, who will be agreed with all these term and conditions will be added in relevant group.</li>

                      </ul>
                      <h4>Disclaimer: </h4>
                      <ul>
                        <li>1-I agree with all above instructions and allow the management to take any legal action.</li>
                        <li>2- Management has the right to cancel the course and to charge a penalty in case of breaching any of the above instructions/rules.</li>
                      </ul>
                    </p>
                    <form id="form_login">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agree"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agree"
                        >
                          I Agree
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Desclimer