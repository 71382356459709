// Base URL LOCAL
// const base_api = "http://127.0.0.1:8000/api/";
// const base_img = "http://127.0.0.1:8000/";
const user = JSON.parse(localStorage.getItem("authUser"));
// Base URL Demo Live
// const base_api = "https://dev-api.NIAISedu.org/api/";
// const base_img = "https://dev-api.NIAISedu.org/";

// Base URL Live
const base_api = "https://aws.niais.org/api/";
const base_img = "https://aws.niais.org/";

module.exports = global.config = {
    URL: {
        api: {
            public: base_api+"public/",
            user: base_api+"user/",
            lms: base_api+"lms/",
        },
        img: {
            public: base_img+"storage/",
        }
    },
    Token : user ? user.token : "",
    Login : user ? user.login : false,
    FBToken: "EAAVcfYF2TwYBAJYxqC7JZCMcZCSRCvF0d87wViJZAfydzuZAdrNoKxOYZBfAHUtfCFatI7AAqDJ28vmD43OjTE4fKyq0av72ScCVh251g6JckGJvWgvzx8sYKvlNtyNLY9EBGsgmkz7bqHyedJ5noHZAP5BnESzS8k4PQZBa9C3I3SMTpR69gkO1mObYcX9wboZD"
    // other global config variables you wish
};