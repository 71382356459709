import React from "react"
import { Link } from "react-router-dom";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  CardGroup,
} from "reactstrap";
import img2 from "../../assets/images/small/img-2.jpg"
import img3 from "../../assets/images/small/img-3.jpg"
const LatestNews = (props) => {

  const news = props.news;
  const announcements = props.announcements;
  console.log(news, 'let');
  return (
    <React.Fragment>
      <Card className="h340 ">
        <CardBody>
          <h4 className="card-title mb-4">Announcements  <Link to={'news'} className="card-title float-end blink_me">see more</Link></h4>

          <Row>

            <Col lg={12}>
              {/* <marquee  direction="up" onmouseover="this.stop()" onmouseout="this.start()"> */}
              <Card>
                <Row className="g-0 align-items-center">
                {
                    announcements.length > 0 ? announcements.map((announcement, i) => (
                      <Col md={12}>
                        <CardBody>
                          <CardTitle className="h5"><b>{announcement.title}</b></CardTitle>
                          <CardText>
                          {announcement.description}
                          </CardText>
                          <CardText>
                            {/* <small className="text-muted">
                       3 mins ago
                        </small> */}
                          </CardText>
                        </CardBody>
                      </Col>
                    ))
                            : ""
                }

                  {
                    news.length > 0 ? news.map((newa, i) => (
                      <Col md={12}>
                        <CardBody>
                          <CardTitle className="h5"><b>{newa.title}</b></CardTitle>
                          <CardText>
                          {newa.description}
                          </CardText>
                          <CardText>
                            {/* <small className="text-muted">
                       3 mins ago
                        </small> */}
                          </CardText>
                        </CardBody>
                      </Col>
                    ))
                            : ""
                }

                </Row>
              </Card>
              
              {/* </marquee> */}
            </Col>
          </Row>
        </CardBody>

      </Card>
    </React.Fragment>
  )
}

export default LatestNews