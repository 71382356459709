import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts"
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const MyProgress = (props) => {

  const attendence = props.attendence;
  const zoomAttend = props.zoomAttend;
  const quizzes = props.quizzes;
  const assigments = props.assigments;
  
  
  const series = [zoomAttend,assigments,0]
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return "Compiling..."
            }
          }
        }
      }
    },
    labels: ['Zoom Sessions', 'Assignment', 'Project'],
    colors: ['#1a5b25','#eeb902','#ff715b'],
  }

  return (
    
      
      <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="280"
      className="apex-charts"
    />
   
   
  )
}

export default MyProgress
