import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Col,
  Row,

} from "reactstrap"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ReactPlayer from 'react-player/vimeo'
import CircularProgress from '@mui/material/CircularProgress';

const Class = props => {
  const [loading, setLoading] = React.useState(true);

  const { module, StdC } = props.location.state ? props.location.state : props.history.push('courses')
  const [Lectures, setLectures] = useState();
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const lectures = await axios.post(global.config.URL.api.lms + 'class', {
        std_c: StdC,
        module: module,
      });
      if (lectures.data.success) {
        setLectures(lectures.data)
      } else {

        props.history.push('courses')
      }

    }
    getData();

  }, []);
  const attend = async (i) => {
    if (i == 0) {

      await axios.post(global.config.URL.api.lms + 'lecture-attendence', {
        id: StdC,
        module: module,
      });
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem={Lectures ? Lectures.course.name + " Module " + Lectures.module + " Lectures" : ""} />
        <Row>
          {Lectures ?
            Lectures.lectures.map((lecture, i) => (
              <Col md={6} sm={6} className="mt-3">
                <Card>
                  <CardActionArea>
                    {loading ?<div> <CircularProgress className='video-loader' /> </div> : ''}
                      <ReactPlayer url={lecture.url}
                        playing={false}
                        controls={true}
                        width='100%'
                        height='250px'
                        responsive={true}
                        onReady={() =>  setLoading(false)}
                        onEnded={() => attend(i)}
                        fileConfig={{ attributes: { preload: 'none' } }}

                      />
                   
                    {/* <CardMedia
                      className='video'
                      component="iframe"
                      height="250"
                      src={lecture.url}
                      alt="Lecture"
                      allowfullscreen="true"
                    /> */}
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {lecture.name}
                      </Typography>

                    </CardContent>
                  </CardActionArea>

                </Card>
              </Col>
            ))
            :
            <Row>
              <Col md={6} sm={6} className="mt-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={'100%'} height={140} />
                  <Skeleton width="60%" />
                </Stack>
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={'100%'} height={140} />
                  <Skeleton width="60%" />
                </Stack>
              </Col>
            </Row>
          }
        </Row>

      </div>
    </React.Fragment>
  )
}
export default Class
