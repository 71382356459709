import moment from 'moment';
export const EVENTS = [

    {
      event_id: 4,
      title: "Event 4",
      start: moment()
      .add(2,'d') //replace 2 with number of days you want to add
      .toDate(),
      color: "#900000"
    },
    
  ];
  