import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Alert, Button, AlertTitle, CardActionArea, CardActions } from '@mui/material';
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom"
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from "reactstrap"
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import swal from 'sweetalert';
import "../../helpers/helper";
import ReactPlayer from 'react-player/vimeo'
import CircularProgress from '@mui/material/CircularProgress';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function ActionAreaCard(props) {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { course } = props.location.state ? props.location.state : props.history.push('courses')
  const [schedules, setSchedules] = useState();
  const [feeback, setFeedback] = useState(true);
  const [feebackQuestions, setFeedBackQuestions] = useState([]);
  const onSubmit = async data => {

    const status = await axios.post(global.config.URL.api.lms + 'store-feedback', data)
    console.log(status, 'status');
    if (status.data.success) {
      setFeedback(false)
    }
    console.log(status.data.success, 'status')
  };
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const schedule = await axios.get(global.config.URL.api.lms + 'course-schedules/' + course);
      setSchedules(schedule.data);
      setFeedback(schedule.data.feed_back);
      setFeedBackQuestions(schedule.data.feed_back_questions);
    }
    getData();

  }, []);


  const attend = async (id, status) => {
    if (status) {
      await axios.get(global.config.URL.api.lms + 'schedule-attendance/' + id);
    }

  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [expandedId, setExpandedId] = React.useState(-1);

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem={schedules ? schedules.course.name + " Sessions" : ""} />
        {
          schedules ?
            feeback ?

              <Row>

                <Col md={12}>
                  <Card className='p-3'>
                    <Alert severity="warning">
                      <AlertTitle>Warning</AlertTitle>
                      First u have to submitt  the Feedback form then ur zoom session will be unlock!! Thanks — <strong>Regards
                        NIAIS management</strong>
                    </Alert>
                  </Card>
                </Col>
                <Col md={12} className='mb-3'>
                  <Card className='p-3'>

                    <h2>Dear Student</h2>
                    <p><b>Note:</b> This questionnaire has been designed by NIAIS management to seek feedback from the student to strengthen the quality of the teaching-learning environment and to look for opportunities to improve teachers’ performance in classroom engagement with students to bring excellence in teaching and learning. Instructor </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <input type={'hidden'} value={schedules.feedback_id} {...register('feedback_id')} />
                      <input type={'hidden'} value={schedules.course.id} {...register('course_id')} />
                      <ul>
                        {feebackQuestions.map((question, i) => (
                          <li><h6>{question.question} <span className='float-end'><div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input mr-1"  {...register(`radio[${question.id}]`, { required: true })} value="A" required />👍 Agree
                            </label>
                          </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input"  {...register(`radio[${question.id}]`)} value="N" />😶 Neutral
                              </label>
                            </div>
                            <div class="form-check-inline disabled">
                              <label class="form-check-label">
                                <input type="radio" class="form-check-input" {...register(`radio[${question.id}]`)} value="D" />👎 Disagree
                              </label>
                            </div></span></h6></li>
                        ))
                        }
                      </ul>

                      <button type='submit' className='btn btn-success btn-block w-100 mt-3'>Submit</button>
                    </form>
                  </Card>
                </Col>
              </Row>
              :

              <Row>
                {schedules.schedule.map((session, i) => (
                  <Col md={4} className='mb-3'>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: green[900] }} aria-label="recipe">
                            P
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={session.title}
                        subheader={moment(session.datetime).format('LLLL')}
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        image={global.config.URL.img.public + session.course.course_detail.img}
                        alt="Paella dish"
                      />
                      <CardContent>
                        <Typography variant="body1" color="text.secondary">
                          {session.description}
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        {
                           session.dropped ?
                           <>
                           
                             <p className='text-danger'>{session.reason}</p>
                             </>
                             :
                        
                        <IconButton aria-label="share">
                          {
                            
                            new Date().getTime() >= new Date(session.datetime).getTime() ?
                              new Date(session.datetime).getTime() >= new Date().getTime() - 3600000 ?

                                <a href={session.link} rel="noreferrer" onClick={() => attend(session.id, session.attend ? false : true)} target={'_blank'}>
                                  <ExitToAppIcon title="Attend Session" /><small> Attend Session</small>
                                </a>
                                :
                                session.attend ?
                                  <p>
                                    <VerifiedIcon /> Attended
                                  </p>
                                
                                  :
                                 
                                  <p>
                                    <CallMissedIcon /> Missed</p>
                              :
                              <LockIcon />
                          }

                        </IconButton>
                        }
                        {

                        }
                        {
                          session.recorded_link ?
                            <ExpandMore

                              onClick={() => {handleExpandClick(i);setLoading(true)}}
                              aria-expanded={expandedId === i}
                              aria-label="show more"
                            >
                              <small> Play video</small>
                              <ExpandMoreIcon />
                            </ExpandMore>
                            : ""
                        }

                      </CardActions>
                      <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Card>
                            <CardActionArea>
                            {loading ?<div> <CircularProgress className='zoom-video-loader' /> </div> : ''}
                              <ReactPlayer url={session.recorded_link}
                                playing={false}
                                controls={true}
                                width='100%'
                                height='250px'
                                onReady={() => setLoading(false)}
                                onEnded={() => attend(session.id, session.attend ? false : true)}
                                fileConfig={{ attributes: { preload: 'none' } }}
                              />
                              {/* <CardMedia
                                className='video'
                                component="iframe"
                                height="250"
                                src={session.recorded_link}
                                alt="Lecture"
                                allowfullscreen="true"
                                onClick={() => alert('ok')}
                              /> */}
                              <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                  {session.title}
                                </Typography>

                              </CardContent>
                            </CardActionArea>

                          </Card>
                        </CardContent>
                      </Collapse>
                    </Card>
                  </Col>
                ))}
              </Row>
            :
            <Row>
              <Col md={4} className='mb-3'>
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon className='text-mute' />
                      </IconButton>
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  />
                  <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                  </CardContent>
                </Card>
              </Col>
              <Col md={4} className='mb-3'>
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon className='text-mute' />
                      </IconButton>
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  />
                  <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                  </CardContent>
                </Card>
              </Col>
              <Col md={4} className='mb-3'>
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon className='text-mute' />
                      </IconButton>
                    }
                    title={
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <Skeleton animation="wave" height={10} width="40%" />
                    }
                  />
                  <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                  <CardContent>
                    <React.Fragment>
                      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                  </CardContent>
                </Card>
              </Col>
            </Row>
        }
      </div>
    </React.Fragment>

  );
}