import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom"
import axios from 'axios';
import { EVENTS } from "./events";
import {
  Col,
  Row,
} from "reactstrap"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { Scheduler } from "@aldabil/react-scheduler";
import LinearProgress from '@mui/material/LinearProgress';
export default function ActionAreaCard(props) {

  const [events, setEvents] = useState([]);
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const revents = await axios.get(global.config.URL.api.lms + 'schedule-calendar');
      setEvents(revents.data);
    console.log(revents)
    }
    getData();

  }, []);

  const Fevents = events.events ? events.events.map((event, i) => (


    {
      title: event.title,
      start: new Date(event.datetime),
      end: new Date(event.datetime),
      color: "#1a5b25",
    }

  ))
    : []
  const Factivities = events.activities ? events.activities.map((activity, i) => (

    {
      title: activity.title,
      start: new Date(activity.datetime),
      end: new Date(activity.datetime),
      color: activity.color,
    }

  ))
    : []

  const FlEvents = Fevents.concat(Factivities);


  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Calendar" />
        <Row>

          {
            FlEvents.length > 0 ?
              <Card>
                <Scheduler
                  form={false}
                  deletable={false}
                  editable={false}
                  draggable={false}
                  view="month"
                  events={FlEvents}
                />
              </Card>
              :
              <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress color="warning" />
                <LinearProgress color="success" />
                <LinearProgress color="warning" />
              </Stack>
          }

        </Row>
      </div>
    </React.Fragment>

  );
}