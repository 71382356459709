import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import {
    Col,
    Row,

} from "reactstrap"
import { useForm } from "react-hook-form";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import swal from 'sweetalert';
import Timer from "./countdown";
const Class = props => {
    const timerIntervalRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [taken,setTaken] = useState(false);
    // Function to get the initial timer value from localStorage or set it to 20 minutes (1200 seconds)
    const getInitialTime = () => {
      const storedTimer = localStorage.getItem('quizTimer');
      if (storedTimer) {
        const parsedTimer = JSON.parse(storedTimer);
        return parsedTimer.timer;
      } else {
        return 600; // 10 minutes
      }
    };
    
    const [timer, setTimer] = useState(getInitialTime);
  
    // Function to handle the timer countdown
    const handleTimer = () => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        // Auto-save or any other action when the timer reaches 0
        saveQuiz();
        clearInterval(timerIntervalRef.current); // Clear the interval to stop the countdown
      }
    };
  
    // Save quiz data (this is just a placeholder, replace with your actual save logic)
    const saveQuiz = () => {
        if(!taken)
        {
            submitButtonRef.current.click();
            console.log('Quiz auto-saved');
        }
       
    };
  
    // Effect to update the timer every second
    useEffect(() => {
      timerIntervalRef.current = setInterval(() => {
        handleTimer();
      }, 1000);
  
      // Cleanup the interval on component unmount
      return () => clearInterval(timerIntervalRef.current);
    }, [timer]);
  
    // Effect to store the timer value in localStorage
    useEffect(() => {
      localStorage.setItem('quizTimer', JSON.stringify({ timer }));
    }, [timer]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = async data => {

        const status = await axios.post(global.config.URL.api.lms + 'submit-week-quiz',data)
       
        if (status.data.success) {
            setStdQuiz(status.data.std_quiz)
            setQuestion(false)
            setAns(true)
           swal("Your Quiz Successfully Submitted",`You Got ${status.data.marks}`,'success');
        }else{
            setStdQuiz(status.data.std_quiz)
            setQuestion(false)
            setAns(true)
        }
        console.log(status.data.success, 'status')
    };
    const { module, StdC } = props.location.state ? props.location.state : props.history.push('quiz-courses')
    const [Question, setQuestion] = useState(false);
    const [Ans, setAns] = useState(false);
    const [Questions, setQuestions] = useState();
    const [stdQuiz, setStdQuiz] = useState();
    
    useEffect(() => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${global.config.Token}`;

        const getData = async () => {
            const questions = await axios.post(global.config.URL.api.lms + 'quiz', {
                std_c: StdC,
                module: module,
            });
            if (questions.data.success) {
                setQuestions(questions.data)
                setQuestion(true)
            } else {
                props.history.push('quiz-courses')
            }

        }
        getData();

    }, []);
    const optionid = null;
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Dashboard" breadcrumbItem={Questions ? Questions.course.name + " Module " + Questions.module.name + " Quiz" : ""} />
                {!taken ?  
            <div class="sticky-top mb-5 w-100">
            <div className="alert alert-danger position-fixed">
  <h5 className='text-danger'><span className='text-dark'>Time Remaining:</span> {Math.floor(timer / 60)} minutes {timer % 60} seconds</h5>
  </div> </div> : ""  
            }
                <Row>
                    {Question ?

                        <Col md={12} className='mb-3'>
                            <Card className='p-3'>
                      
                                {Questions.questions.length > 0 ?
                                <form onSubmit={handleSubmit(onSubmit)}>
                                       <input {...register(`week`)} type="hidden" value={ Questions.module.id}/>
                                        <input  {...register(`total`)} type="hidden" value={ Questions.questions.length }/>
                                        <input  {...register(`stdc`)} type="hidden" value={ StdC }/>
                                            <ul>
                                                {Questions.questions.map((question, i) => (
                                                    <li>
                                                        <input
                                                            type="hidden"
                                                            {...register(`questions[${i}]`)}
                                                            value={question.id} />
                                                        <h2 className='mb-3'>{question.question} </h2>
                                                        <h4 className='mb-5'> {question.options.map((option, i) => (
                                                            <div class="mb-3">
                                                                <label class="form-check-label">
                                                                    <input type="radio" class="form-check-input ml-3"  {...register(`answers[${question.id}]`)} value={option.id} />{option.option}
                                                                </label>
                                                            </div>
                                                            
                                                        ))}</h4>
                                                    </li>
                                                ))
                                                }
                                            </ul>

                                            <button type='submit' className='btn btn-success btn-block w-100 mt-3'>Submit</button>
                                        </form>
                                        : "Quiz Is Compiling ...." }
                                    </Card>
                                </Col>
                                
                                : Ans ?
                                <Col md={12} className='mb-3'>
                                <Card className='p-3'>
    
    
                                    <form >
                                           
                                                <ul>
                                                    {stdQuiz.ans.map((anss, i) => (
                                                      
                                                        
                                                        <li>
                                                            
                                                            <h2 className='mb-3'>{anss.question ? anss.question.question : ""} </h2>
                                                            <h4 className='mb-5'> {anss.question ? anss.question.options.map((option, i) => (
                                                                <div class="mb-3">
                                                                    <label className={`form-check-label ${option.correct == 1 ?  "text-success" :
                                                                    anss.option && anss.option.id === option.id ? "text-danger" : ""}`}>
                                                                        <input type="radio" defaultChecked={option.correct == 1 ? true : anss.option && anss.option.id === option.id ? true : false} className={`form-check-input ml-3`}  disabled/>{option.option}
                                                                    </label>
                                                                </div>
                                                            )): ""}</h4>
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
    
                                            </form>
                                        </Card>
                                    </Col>
                                : 
                                <Row>
                                    <Col md={6} sm={6} className="mt-3">
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={'100%'} height={140} />
                                            <Skeleton width="60%" />
                                        </Stack>
                                    </Col>
                                    <Col md={6} sm={6} className="mt-3">
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={'100%'} height={140} />
                                            <Skeleton width="60%" />
                                        </Stack>
                                    </Col>
                                </Row>
                    }
                            </Row>

                        </div>
        </React.Fragment>
                )
}
                export default Class
