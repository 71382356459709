import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  CardGroup,
  Badge,
  Button,
} from "reactstrap";
import { Input } from '@mui/material';
import moment from 'moment';

// import images
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom"
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const Project = props => {

  const [projects, setProjects] = useState();
  const [now, setNow] = useState();
  const [project, setProject] = useState(null);
  const [file, setFile] = useState()
  const [error, setError] = useState(null)
  const [course, setCourse] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id, course) => {
    setCourse(course);
    setProject(id);
    setOpen(true)
  };
  const handleClose = () => { setOpen(false); setError(false);setProject(null) }
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${global.config.Token}`;

  const getData = async () => {
    const ResProjects = await axios.get(global.config.URL.api.lms + 'my-projects');
    console.log(ResProjects.data,)
    setProjects(ResProjects.data);
    setNow(ResProjects.data.now)
  }
  useEffect(() => {

    getData();
  }, []);
  function handleChange(event) {
    setFile(event.target.files[0])
  }

  function handleSubmit(event) {
    event.preventDefault()
    const url = global.config.URL.api.lms + 'submit-project';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', project);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      if (response.data.success) {
        getData();
        setOpen(false);
      } else if (response.data.success == false) {
        setError(response.data.message)
      }

    });

  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="Projects" />


        <Row>
          {
            projects ? <>
              {
                projects.projects.length > 0 ?
                  <>
                    {
                      projects.projects.map((project, i) => (
                        <Col md={12}>
                          <Card body>
                            <CardTitle className="mt-0">
                              <h6 className="float-end m-0"><Badge className="bg-primary">
                                Deadline: {moment(project.project.deadline).format("LL")}
                              </Badge></h6>
                              <h4 className="m-0"> {project.project.course ? project.project.course.name : ""} <sup>B-{project.project.batch ? project.project.batch.number : ""}</sup> {project.project.deadline >= now ? <a href={global.config.URL.img.public + project.project.file} target={'_blank'}><i class="mdi mdi-download text-primary blink_me" title="Download project"></i></a> : null}</h4>

                            </CardTitle>
                            <CardText>
                              {project.project.text}
                            </CardText>
                            <p>

                              {project.status === 0 && project.project.deadline >= now ? (
                                <Badge pill className="rounded-pill bg-warning">
                                  New
                                </Badge>
                              ) : project.status === 0 && project.project.deadline < now ? (
                                <Badge pill className="rounded-pill bg-danger">
                                  Missed
                                </Badge>
                              ) : project.status === 1 ? (
                                <Badge pill className="rounded-pill bg-success">
                                  Submitted
                                </Badge>
                              ) : null}
                              <p className='float-end'>

                                {project.status === 0 && project.project.deadline < now ? (
                                  <Badge pill className="rounded-pill bg-danger">
                                    Grade: <strong>{project.grade} </strong>
                                  </Badge>

                                ) : project.status === 1 && project.checked === 1 ? (
                                  <>
                                    <Badge pill className="rounded-pill bg-primary">
                                    Grade: <strong>{project.grade} </strong>
                                    </Badge><br></br>
                                    <Badge pill className="rounded-pill bg-success">
                                      Comments: <strong>{project.comment}</strong>
                                    </Badge>

                                  </>
                                ) : project.status === 1 && project.checked === 0 ? (
                                  <Badge pill className="rounded-pill bg-warning">
                                    Checking in Progress ...
                                  </Badge>
                                ) : null}

                              </p>
                            </p>
                            {
                              project.project.deadline >= now ?
                                <Button onClick={() => handleOpen(project.id, project.project.course.name)} className="btn btn-primary bg-primary waves-effect btn-sm waves-light"
                                >
                                  <i class="mdi mdi-upload text-light" ></i> {project.status == 1 ? "Update Project" : "Submit Project"}
                                </Button> : ""
                            }

                          </Card>
                          <hr></hr>
                        </Col>

                      ))}
                  </>

                  :
                  <Col md={12}>
                    <Card body>

                      <CardText>
                        <h2>Dear NIAISians,</h2> we are glad to see you here but there's no need to worry if you find us as an empty ground. You'll get your projects once your modules and course content is finished (After 08 Module) . Keep in touch with us and don't forget to follow your course calender.
                      </CardText>

                    </Card>
                  </Col>
              }

            </> :
              <Col md={12} sm={12} className="mt-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={'100%'} height={100} />
                  <Skeleton width="60%" />
                  <Skeleton width="10%" />
                  <Skeleton width="100%" height={50} />
                </Stack>
              </Col>
          }


        </Row>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Upload {course} Project
            </Typography>
            <form onSubmit={handleSubmit} className="m-3 form-froup">

              <input type="file" className='form-control' onChange={handleChange} />
              {error ? <p className='m-0 text-danger'>{error} </p> : ""}
              <Button type='submit' className="btn mt-3 btn-primary bg-primary waves-effect btn-sm waves-light"
              >
                <i class="mdi mdi-upload text-light" ></i>  Upload Project
              </Button>
            </form>
            <Typography id="modal-modal-description" className='text-primary' sx={{ mt: 2 }}>
              File should be in pdf,docx,doc,xlsx,ppt,pptx format
            </Typography>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default Project
