import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Pages 
//Calendar
import Calendar from "../pages/Calendar/index"
//Announcement
import Announcement from "../pages/Announcement/index"
//Assignment
import Assignment from "../pages/Assignment/index"
//Project
import Project from "../pages/Project/index"
import Zoom from "../pages/Zoom/index"
import ZoomCourses from "../pages/Zoom/ZoomCourses"
import WhatsAPP from "../pages/WhatsAPP/index"
import WhatsAPPTerms from "../pages/WhatsAPPTerms/index"
import Desclimer from "../pages/Desclimer/index"
//Quiz
import QuizCourses from "../pages/Quiz/QuizCourses"

import Class from "../pages/Class/index"
import Courses from "../pages/Courses/index"
import Modules from "../pages/Modules/index"
import Quizs from "../pages/QuizCourses/index"
import Quiz from "../pages/Quiz/index"

// Authentication related pages

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
//Certificates
import Certificates from "../pages/Certificates/index"

// Results
import Results from "../pages/Results/index"
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/calendar", component: Calendar },
  { path: "/news", component: Announcement },
  { path: "/assignments", component: Assignment },
  { path: "/projects", component: Project },
  { path: "/zoom-courses", component: ZoomCourses },
  { path: "/zoom-sessions", component: Zoom },
  { path: "/courses", component: Courses },
  { path: "/modules",   component: Modules },
  { path: "/whatsapp", component: WhatsAPP },
  { path: "/whatsapp-terms", component: WhatsAPPTerms },
  { path: "/quiz-courses", component: QuizCourses },
  { path: "/quizzes", component: Quizs },
  { path: "/quiz", component: Quiz },
  { path: "/certificates", component: Certificates },
  { path: "/results", component:Results },
  // { path: "/course-schedule/:course", component:Schedule },
  
  { path: "/class", component: Class },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/disclaimer", component:Desclimer },
]

export { userRoutes, authRoutes }