import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { decode } from "html-entities";
import { Button, CardActionArea, CardActions } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import courseImg from "../../assets/images/Digital-Marketing.jpg";
import webimg6 from "../../assets/images/webimg6.jpg";
import webimg2 from "../../assets/images/webimg2.jpg";
import { Link, useHistory } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import {
  Col,
  Row,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import Marquee from "react-fast-marquee";
import "../../helpers/helper";


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function BasicTabs(props) {

  const [value, setValue] = React.useState(0)
  const [invoices, setInvoice] = useState(null);
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const invoices = await axios.get(global.config.URL.api.lms + 'my-results');
      setInvoice(invoices.data.invoices);
    }
    getData();

  },[]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="My Results" />
       
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
                invoices ? invoices.map((batch, i) => (
                  <Tab label={"Batch " + batch.batch.number} {...a11yProps(i)} />
                ))
                  :
                  ""
              }
            </Tabs>
          </Box>
          
        {

            invoices ? 
            invoices.length > 0 ?
            invoices.map((batch1, i) => (
              <TabPanel value={value} index={i}>
                <Row>
                  {
                   <Col md={12} >
                   <Card body className='p-3'>
       
                     <CardText>
                      {
                        batch1.result.prize ? <><div
                        dangerouslySetInnerHTML={{
                          __html:  batch1.result.prize.message
                            ? decode( batch1.result.prize.message)
                            : ""
                        }}
                      /> </> : 
                       <> <h4 class="text-danger">Dear PFTPian,</h4>
                       <b >
                        PFTP is pleased to announce that merit has been officially declared.
                        you are not qualified for the scholarship as you have scored less than merit in your overall academics report, therefore you will receive your certificate. 
                        We wish you best of luck for future.
                        <br/>
                        Regards, PFTP Management.
                    </b>
                    </>
                      }
                       
                     </CardText>
       
                   </Card>
                 </Col> 
                  }
                </Row>
              </TabPanel>
            ))
            : <Col md={12} >
            <Card body className='p-3'>

              <CardText>

                <h2> Empty Result</h2>
              </CardText>

            </Card>
          </Col>  :
              <Row>
                <Col md={12} sm={12} className="mt-3">
                  <Stack spacing={1}>
                   
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
                
              </Row>
          } 
        </Box>
      </div>
    </React.Fragment>
  );
}